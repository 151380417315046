var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Onboard from "@web3-onboard/core";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import { Buffer } from 'buffer';
// @ts-ignore
window.Buffer = Buffer;
var connected_wallets = [];
function onboard_init() {
    window.onboard = Onboard({
        wallets: [coinbaseWalletModule()],
        connect: {
            autoConnectAllPreviousWallet: true
        },
        chains: [
            {
                id: "0x1",
                token: "ETH",
                namespace: "evm",
                label: "Ethereum Mainnet",
                rpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/Dpy1SDZpvRIsWjKauG503K6fHEImfvGa'
            },
            {
                id: 137,
                token: 'MATIC',
                label: 'Polygon',
                rpcUrl: 'https://polygon-rpc.com/'
            },
            {
                id: 80002,
                token: 'MATIC',
                label: 'Amoy',
                rpcUrl: 'https://rpc-amoy.polygon.technology/'
            },
            {
                id: 11155111,
                token: 'ETH',
                label: 'Sepolia',
                rpcUrl: 'https://rpc.sepolia.org/'
            },
        ],
        appMetadata: {
            name: "DressX Onchain",
            icon: "https://onchain.dressx.com/static/assets/images/favicon_16x16.png",
            logo: "https://onchain.dressx.com/static/assets/images/favicon_16x16.png",
            description: "DRESSX Fashion Marketplace",
            recommendedInjectedWallets: [
                { name: "Coinbase", url: "https://wallet.coinbase.com/" }
            ]
        }
    });
    console.log(["onboard_init onboard", window.onboard]);
    window.onboard.state.select('wallets').subscribe(function (update) {
        return __awaiter(this, void 0, void 0, function () {
            var onboard_disconnected, onboard_connected;
            return __generator(this, function (_a) {
                console.log(["onboard_init state", update]);
                if (!update.length && !connected_wallets.length) {
                    return [2 /*return*/];
                }
                else if (!update.length && connected_wallets.length > 0) {
                    console.log(["onboard_init state Disconnected", update]);
                    onboard_disconnected = new Event('onboard_disconnected');
                    document.querySelector("body").dispatchEvent(onboard_disconnected);
                    return [2 /*return*/];
                }
                connected_wallets = update;
                onboard_connected = new Event('onboard_connected');
                document.querySelector("body").dispatchEvent(onboard_connected);
                return [2 /*return*/];
            });
        });
    });
}
;
document.querySelector("body").addEventListener("onboard_connect", function () {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            onboard_init();
            return [2 /*return*/];
        });
    });
});
document.querySelector("body").addEventListener("onboard_login", function () {
    return __awaiter(this, void 0, void 0, function () {
        var onboard_logined;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onboard_init();
                    return [4 /*yield*/, window.onboard.connectWallet()];
                case 1:
                    _a.sent();
                    onboard_logined = new Event('onboard_logined');
                    document.querySelector("body").dispatchEvent(onboard_logined);
                    return [2 /*return*/];
            }
        });
    });
});
document.querySelector("body").addEventListener("onboard_disconnect", function (event) {
    return __awaiter(this, void 0, void 0, function () {
        var primaryWallet;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("onboard_disconnect disconnect...");
                    return [4 /*yield*/, window.onboard.state.get().wallets];
                case 1:
                    primaryWallet = (_a.sent())[0];
                    if (!primaryWallet) return [3 /*break*/, 3];
                    return [4 /*yield*/, window.onboard.disconnectWallet({ label: primaryWallet.label })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    event.detail.on_exec();
                    return [2 /*return*/];
            }
        });
    });
});
